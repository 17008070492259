import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { zCandidateType, zFunction, zIndustry } from 'client/zod.gen';
import { zFiltersArray } from 'helpers/router';
import { dateRangeIntervalSchema } from 'helpers/zod';

const schema = z
  .object({
    advisor_id: zFiltersArray(),
    candidate: z.string().optional(),
    function_expertise: zFiltersArray(zFunction),
    in_the_red_advisor_id: zFiltersArray(),
    industry_expertise: zFiltersArray(zIndustry),
    interval: dateRangeIntervalSchema,
    peer: z.string().optional(),
    period: z
      .union([z.literal('upcoming'), z.literal('recent')])
      .optional()
      .default('upcoming'),
    program: zFiltersArray(zCandidateType),
  })
  .partial({
    advisor_id: true,
    candidate: true,
    in_the_red_advisor_id: true,
    peer: true,
  })
  .optional()
  .default({
    interval: '3mo',
    period: 'upcoming',
  })
  .catch({
    interval: '3mo',
    period: 'upcoming',
  });

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute('/_private/_route/_internal/performance/')(
  {
    validateSearch: schema,
  },
);
