import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { getCandidateV2Options } from 'client/@tanstack/react-query.gen';

const schema = z.object({
  candidate: z.string().optional().catch(undefined),
});

export const Route = createFileRoute(
  '/_private/_route/_internal/trending/new/',
)({
  beforeLoad: async ({ context, search }) => {
    const { queryClient } = context;

    if (search.candidate) {
      await queryClient.ensureQueryData({
        ...getCandidateV2Options({
          path: {
            candidate_uuid: search.candidate,
          },
        }),
      });
    }
  },
  validateSearch: schema,
});
