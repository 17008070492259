import type { AxiosError } from 'axios';
import { useState } from 'react';

import type { APIError } from 'types/api';

export const getAxiosError = (error: AxiosError): APIError => {
  // TODO: Update types
  // This interface comes from the generated client error handling.
  // @ts-ignore
  if (error.body) {
    // @ts-ignore
    return error.body as APIError;
  }

  if (error.response) {
    return error.response.data as APIError;
  }

  if (error.request) {
    return error.request;
  }

  return {
    detail: error.message,
  };
};

const isAxiosError = (error: AxiosError, messages: APIError['detail'][]) => {
  if (!error.response) {
    return false;
  }

  const { detail } = getAxiosError(error);
  return error.response.status !== 200 && messages.includes(detail);
};

export const isAxiosErrorNotFound = (error: Error | null | undefined) => {
  if (!error) {
    return false;
  }

  const e = error as AxiosError;
  return e.response?.status === 404 || e.status === 404;
};

export const isAxiosErrorPersonLoggedOut = (error: AxiosError) =>
  isAxiosError(error, ['Person has logged out of the system']);

export const isAxiosErrorRefreshFailed = (error: AxiosError) =>
  // Invalid token is legacy value, everything else is current value
  // TODO: import values from generated API client
  isAxiosError(error, [
    'Invalid token',
    'Invalid refresh token',
    'No sub in token',
    'Refresh token expired',
    'Deactivated user',
  ]);

export const useStateAxiosErrorDetail = () => useState<APIError['detail']>('');
