import { createFileRoute, redirect } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { z } from 'zod';

import {
  getPeopleAdvisorsOptions,
  postAdminPeersFilterOptions,
} from 'client/@tanstack/react-query.gen';
import type { Person } from 'client/types.gen';
import { zPeerStatus } from 'client/zod.gen';
import { zFiltersArray } from 'helpers/router';

const schema = z.object({
  advisor: zFiltersArray(),
  assignee: zFiltersArray(z.string(), ['me']),
  compare: z.array(z.string()).optional(),
  compareDialog: z
    .union([z.literal('about'), z.literal('peers'), z.literal('profile')])
    .optional()
    .catch(undefined),
  creator: zFiltersArray(),
  filtersDialog: z
    .union([
      z.literal('advisor'),
      z.literal('assignee'),
      z.literal('creator'),
      z.literal('status'),
    ])
    .optional()
    .catch(undefined),
  order: z
    .union([z.literal('asc'), z.literal('desc')])
    .optional()
    .default('desc')
    .catch('desc'),
  page: z.coerce.number().optional().default(1).catch(1),
  results: z.coerce.number().optional().default(10).catch(10),
  sort: z
    .union([
      z.literal('assignee'),
      z.literal('candidate_name'),
      z.literal('candidate_to_name'),
      z.literal('creator'),
      z.literal('scheduled'),
      z.literal('status'),
      z.literal('updated'),
    ])
    .optional()
    .default('updated')
    .catch('updated'),
  status: zFiltersArray(zPeerStatus),
  view: z.string().optional(),
});

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute('/_private/_route/_internal/peers/')({
  beforeLoad: () => {
    if (!posthog.isFeatureEnabled('project-p2p')) {
      throw redirect({ replace: true, to: '/' });
    }
  },
  loaderDeps: ({
    search: {
      advisor,
      assignee,
      creator,
      order,
      page,
      results,
      sort,
      status,
      view,
    },
  }) => ({
    advisor,
    assignee,
    creator,
    order,
    page,
    results,
    sort,
    status,
    view,
  }),
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  loader: async ({ context, deps }) => {
    const { queryClient, user } = context;
    const pAdvisors = queryClient.ensureQueryData(
      getPeopleAdvisorsOptions({
        query: {
          // TODO: Implement pagination so we can change results size and navigate
          // between pages
          size: 100,
        },
      }),
    );
    const pPeers = queryClient.ensureQueryData(
      postAdminPeersFilterOptions({
        body: {
          filter_data: {
            advisor_uuid: replaceMeWithUuid(deps.advisor, user),
            assignee_uuid: replaceMeWithUuid(deps.assignee, user),
            recommender_uuid: replaceMeWithUuid(deps.creator, user),
            status: deps.status ?? [],
          },
          page: deps.page,
          page_size: deps.results,
          phrase: '',
          sort_rules: [
            {
              column: deps.sort,
              direction: deps.order,
            },
          ],
        },
      }),
    );
    await Promise.all([pAdvisors, pPeers]);
  },
  validateSearch: schema,
});

export const replaceMeWithUuid = (
  uuids: string[] | undefined,
  user: Person,
): string[] => (uuids ?? []).map((uuid) => (uuid === 'me' ? user.id! : uuid));
