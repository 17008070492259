import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { getTeamOptions } from 'client/@tanstack/react-query.gen';
import { zPipelineTypeFilter } from 'client/zod.gen';
import { zFiltersArray } from 'helpers/router';

export const schema = z.object({
  from: z.string().optional().catch(undefined),
  function_expertise: zFiltersArray(),
  industry_expertise: zFiltersArray(),
  team_slug: z.string().optional().catch(undefined),
  type: zPipelineTypeFilter.optional().catch(undefined),
});

export const Route = createFileRoute(
  '/_private/_route/_internal/pipelines/new',
)({
  beforeLoad: async ({ context, search }) => {
    if (search.team_slug) {
      await context.queryClient.ensureQueryData({
        ...getTeamOptions({ path: { team_slug: search.team_slug } }),
      });
    }
  },
  validateSearch: schema,
});
