import { useMatchRoute } from '@tanstack/react-router';
import { useState } from 'react';

import { usePageContext } from './context';

import { Loading } from 'components/Loading';
import type { LoadingSize } from 'components/Loading/types';
import type {
  LayoutPanelContextOptions,
  LayoutPanelContextProps,
  LayoutPanelStateProps,
} from 'types/layout';

export const getSuspenseFallback = (size: LoadingSize) => (
  <Loading.Center>
    <Loading size={size} />
  </Loading.Center>
);

export const useLayoutPanelContext = ({
  candidateId,
}: LayoutPanelContextOptions): LayoutPanelContextProps => {
  const { isLazyForm } = usePageContext();

  const matchRoute = useMatchRoute();

  const matchTeamIntroductionsPath = matchRoute({
    to: '/teams/$teamId/introductions',
  });
  const matchTeamNetworkPath = matchRoute({ to: '/teams/$teamId/network' });
  const matchTeamPipelinePath = matchRoute({
    to: '/teams/$teamId/pipelines/$pipelineId',
  });

  if (!isLazyForm) {
    if (matchTeamIntroductionsPath) {
      return {
        routeMatch: matchTeamIntroductionsPath,
        type: 'teamIntroduction',
      };
    }

    if (matchTeamNetworkPath) {
      return {
        routeMatch: matchTeamNetworkPath,
        type: 'teamNetwork',
      };
    }

    if (matchTeamPipelinePath) {
      return {
        routeMatch: matchTeamPipelinePath,
        type: 'teamPipelineIntroduction',
      };
    }
  }

  if (candidateId) {
    return {
      routeMatch: undefined,
      type: 'candidate',
    };
  }

  return {
    routeMatch: undefined,
    type: undefined,
  };
};

export const useLayoutPanelState = <T,>(initialState?: T) => {
  type Props = LayoutPanelStateProps<T>;

  const [layoutPanelState, setLayoutPanelState] = useState<
    Props['layoutPanelState']
  >(() => initialState ?? null);

  const onCancelLayoutPanel: Props['onCancelLayoutPanel'] = () => {
    setLayoutPanelState(null);
  };

  return {
    layoutPanelState,
    onCancelLayoutPanel,
    setLayoutPanelState,
  };
};
