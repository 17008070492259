import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import {
  getCandidateOffLimitsCompaniesOptions,
  getCandidateOffLimitsPersonsOptions,
  getPeopleAdvisorsOptions,
} from 'client/@tanstack/react-query.gen';

const schema = z.object({
  formType: z
    .enum(['networking', 'personal', 'professional'])
    .optional()
    .catch(undefined),
});

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/profile/',
)({
  loader: async ({ context, params }) => {
    const { queryClient } = context;
    const pAdvisors = queryClient.ensureQueryData(
      getPeopleAdvisorsOptions({
        query: {
          // TODO: Implement pagination so we can change results size and navigate
          // between pages
          size: 100,
        },
      }),
    );
    const pOffLimitCompanies = queryClient.ensureQueryData(
      getCandidateOffLimitsCompaniesOptions({
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    const pOffLimitPeople = queryClient.ensureQueryData(
      getCandidateOffLimitsPersonsOptions({
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    await Promise.all([pAdvisors, pOffLimitCompanies, pOffLimitPeople]);
  },
  validateSearch: schema,
});
