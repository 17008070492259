import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import {
  zConnectionStatus,
  zFunction,
  zIndustry,
  zInterestRole,
  zOperatingHighlights,
} from 'client/zod.gen';

const schema = z.object({
  connection_status: z.array(zConnectionStatus).optional().catch([]),
  filtersDialog: z
    .union([
      z.literal('industry_expertise'),
      z.literal('function_expertise'),
      z.literal('operating_highlights'),
      z.literal('interest_role_operating'),
      z.literal('interest_role_board_advisory'),
      z.literal('connection_status'),
    ])
    .optional()
    .catch(undefined),
  function_expertise: z.array(zFunction).optional().catch([]),
  industry_expertise: z.array(zIndustry).optional().catch([]),
  interest_role_board_advisory: z.array(zInterestRole).optional().catch([]),
  interest_role_operating: z.array(zInterestRole).optional().catch([]),
  operating_highlights: z.array(zOperatingHighlights).optional().catch([]),
});

export type Schema = z.infer<typeof schema>;

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/candidates',
)({
  validateSearch: schema,
});
