import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import {
  getCandidateStatsOptions,
  postCandidateRecommendationsFilterOptions,
} from 'client/@tanstack/react-query.gen';
import {
  zOrgType,
  zPipelineRoleType,
  zRecommendationFilterStatus,
  zRecommendationReviewState,
} from 'client/zod.gen';
import { getPeriodDates } from 'helpers/date';
import { zFiltersArray } from 'helpers/router';
import { dateRangeIntervalSchema } from 'helpers/zod';

export const schema = z.object({
  company: zFiltersArray(),
  companyType: zFiltersArray(zOrgType),
  filtersDialog: z
    .union([
      z.literal('company'),
      z.literal('companyType'),
      z.literal('roleType'),
      z.literal('response'),
      z.literal('recommendationType'),
    ])
    .optional()
    .catch(undefined),
  page: z.coerce.number().optional().default(1).catch(1),
  period: dateRangeIntervalSchema,
  recommendationType: zFiltersArray(z.enum(['global', 'pipeline', 'trending'])),
  response: zFiltersArray(zRecommendationReviewState),
  results: z.coerce.number().optional().default(10).catch(10),
  roleType: zFiltersArray(zPipelineRoleType),
  statsIntros: z
    .union([z.literal('completed'), z.literal('requests')])
    .optional()
    .default('requests')
    .catch('requests'),
  statsType: z
    .union([z.literal('role'), z.literal('submission'), z.literal('team')])
    .optional()
    .default('submission')
    .catch('submission'),
  tab: zRecommendationFilterStatus.default('all').catch('all'),
});

export type SearchSchema = z.infer<typeof schema>;

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/performance/',
)({
  loaderDeps: ({
    search: {
      company,
      companyType,
      page,
      period,
      recommendationType,
      response,
      results,
      roleType,
      statsType,
    },
  }) => ({
    company,
    companyType,
    page,
    period,
    recommendationType,
    response,
    results,
    roleType,
    statsType,
  }),
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  loader: async ({ context, deps, params }) => {
    const { queryClient } = context;
    const dates = getPeriodDates({ period: deps.period });
    const pRecommendations = queryClient.ensureQueryData(
      postCandidateRecommendationsFilterOptions({
        body: {
          date_from: dates.from?.toISO() ?? undefined,
          date_to: dates.to?.toISO() ?? undefined,
          filter_data: {
            company_id: deps.company ?? [],
            company_type: deps.companyType ?? [],
            response: deps.response ?? [],
            role_type: deps.roleType ?? [],
            type: deps.recommendationType ?? [],
          },
          page: deps.page,
          page_size: deps.results,
          sort_rules: [
            {
              column: 'published_on',
              direction: 'desc',
            },
          ],
        },
        path: {
          candidate_uuid: params.candidateId,
        },
      }),
    );
    const pStats = queryClient.ensureQueryData(
      getCandidateStatsOptions({
        path: {
          candidate_uuid: params.candidateId,
        },
        query: {
          date_from: dates.from?.toISO() ?? undefined,
          date_to: dates.to?.toISO() ?? undefined,
          type: deps.statsType,
        },
      }),
    );
    await Promise.all([pRecommendations, pStats]);
  },
  validateSearch: schema,
});
