import { createFileRoute, redirect } from '@tanstack/react-router';

import { schema } from './performance/index.route';

export const Route = createFileRoute(
  '/_private/_route/_internal/candidates/$candidateId/recommendations/',
)({
  beforeLoad: ({ params, search }) => {
    throw redirect({
      params: {
        candidateId: params.candidateId,
      },
      replace: true,
      search,
      to: '/candidates/$candidateId/performance',
    });
  },
  validateSearch: schema,
});
