import type { QueryObserverResult } from '@tanstack/query-core';
import { createContext, useContext } from 'react';

import type { PageContext as IPageContext } from 'types/context';

// often, we want to render component state based on the status of queries it
// consumes. we add `empty` status, which is a `success` state containing an
// empty state response
// (https://toptal.com/designers/ux/empty-state-ux-design)
export type ViewStatus = QueryObserverResult['status'] | 'empty';

export const PageContext = createContext<IPageContext>({});

export const ViewStatusContext = createContext<ViewStatus>('success');

export const usePageContext = () => useContext(PageContext);

export const useViewStatusContext = () => useContext(ViewStatusContext);

const noop = () => {};

type TUseState<T> = readonly [T, React.Dispatch<React.SetStateAction<T>>];

type CurrentStep = number;
type MaxSteps = number;
type Step = readonly [CurrentStep, MaxSteps];
export const FormStepContext = createContext<TUseState<Step>>([[-1, -1], noop]);
export const useFormStepContext = () => useContext(FormStepContext);
