import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { zPipelineTypeFilter } from 'client/zod.gen';

export const teamPipelinesSearchSchema = z.object({
  type: z.array(zPipelineTypeFilter).optional().catch([]),
});

export const Route = createFileRoute(
  '/_private/_route/teams/$teamId/pipelines/',
)({
  validateSearch: teamPipelinesSearchSchema,
});
