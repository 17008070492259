import type { GetPeopleCurrentResponse } from 'client/types.gen';
import { CompanyPaymentStatus } from 'types/company';
import type { TeamMember } from 'types/people';
import type { Team } from 'types/team';
import { TeamClientSize } from 'types/team';

export * from './misc';

// @ts-ignore
const company: Company = {
  name: '',
  payment_status: CompanyPaymentStatus.Free,
  slug: '',
  type: 'search',
};

const teamNames = [
  'Finacial Services Board Seats',
  'My Team',
  'Team Bonnie',
  'Fintech Growth Specialists',
];

const team: Team = {
  additional_details: '',
  client_size: TeamClientSize.Established,
  company,
  diverse_candidates: 0,
  functional_focus: ['ceo'],
  industry_focus: ['nonprofits'],
  slug: '',
  team_name: '',
  total_candidates: 0,
  uuid: '',
};

const teams: Team[] = [
  {
    ...team,
    functional_focus: ['customer_success'],
    industry_focus: ['enterprise_software'],
    slug: 'financial-services-board-seats',
    team_name: teamNames[0],
    uuid: '1',
  },
  {
    ...team,
    slug: 'my-team',
    team_name: teamNames[1],
    uuid: '2',
  },
  {
    ...team,
    slug: 'team-bonnie',
    team_name: teamNames[2],
    uuid: '3',
  },
];

const person: Required<GetPeopleCurrentResponse> = {
  country: 'United States',
  email: 'this+is+fake+data+stop@reporting.please',
  first_login: '',
  first_name: 'Y',
  has_forgotten_password: false,
  has_set_password: true,
  has_unverified_new_email: false,
  id: '1',
  last_login: '',
  last_name: 'O',
  name: '',
  newly_invited: false,
  otp_auth_fail: '',
  person_image:
    'https://images.unsplash.com/photo-1510227272981-87123e259b17?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=3759e09a5b9fbe53088b23c615b6312e',
  person_name: '',
  postal_code: '90201',
  roles: [
    {
      company,
      team_slug: teams[0].slug,
      title: 'Search Team Lead',
    },
    {
      company,
      team_slug: teams[1].slug,
      title: 'Search Team Member',
    },
    {
      company,
      team_slug: teams[2].slug,
      title: 'Search Team Member',
    },
  ],
  unverified_new_email: 'jacqueline@banffadvisors.com',
  uuid: '1',
};

export const testTeamMember: {
  data: TeamMember;
} = {
  data: {
    ...person,
    country: person.country ?? '',
    title: '',
    zipcode: '',
  },
};
