import { useNavigate, useSearch } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { z } from 'zod';

import { useEffectAfterRender, useRefValue } from 'helpers/hooks';
import type { IForms } from 'types/form';
import type { IModals } from 'types/modal';
import type { SearchParams, SearchParamsActions } from 'types/router';

type ActionHandler = (action: {
  action: SearchParamValue;
  form: SearchParamValue<IForms['type']>;
  modal: SearchParamValue<IModals['type']>;
}) => void;

type ActionTrigger = 'form' | 'formAction' | 'modal' | 'modalAction';

interface Options {
  enabled?: boolean;
}

type SearchParamValue<T extends string = string> = T | null | undefined;

/**
 * @link https://ourcodeworld.com/articles/read/608/how-to-camelize-and-decamelize-strings-in-javascript
 */
const camelize = (text: string): string =>
  text.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2) =>
    p2 ? p2.toUpperCase() : p1.toLowerCase(),
  );

/**
 * @link https://ourcodeworld.com/articles/read/608/how-to-camelize-and-decamelize-strings-in-javascript
 */
const decamelize = <T extends string>(text: T, separator: string): T =>
  text
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`)
    .toLowerCase() as T;

const getCamelCasedValue = <T extends string = string>(
  value: SearchParamValue,
): SearchParamValue<T> =>
  value
    ? (camelize(value) as SearchParamValue<T>)
    : (value as SearchParamValue<T>);

export const useSearchParamsAction = (
  callback: ActionHandler,
  triggers: ActionTrigger[],
  deps: ReadonlyArray<any> = [],
) => {
  const search = useSearch({
    strict: false,
  });
  const action = 'action' in search ? search.action : undefined;
  const formParam = 'form' in search ? search.form : undefined;
  const modalParam = 'modal' in search ? search.modal : undefined;

  const form = getCamelCasedValue<IForms['type']>(formParam);
  const modal = getCamelCasedValue<IModals['type']>(modalParam);

  const refCallback = useRefValue(callback);
  const refTriggers = useRef(triggers);

  // Do not pass dependencies as it would cause this method to run every time
  // we open form or modal, leading to the callback being called multiple times
  // and causing state bugs.
  const triggerSearchParamsAction = useCallback(() => {
    const canTriggerAction = (value: ActionTrigger): boolean => {
      switch (value) {
        case 'form':
          return Boolean(form && !action);
        case 'formAction':
          return Boolean(form && action);
        case 'modal':
          return Boolean(modal && !action);
        case 'modalAction':
          return Boolean(modal && action);
        default:
          return false;
      }
    };

    if (refTriggers.current.some(canTriggerAction)) {
      refCallback.current({ action, form, modal });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    triggerSearchParamsAction();
  }, [triggerSearchParamsAction]);

  return {
    triggerSearchParamsAction,
  };
};

export const useSearchParamsActionableState = (
  type: Extract<keyof SearchParamsActions, 'form' | 'modal'>,
  atom?: IForms | IModals,
) => {
  const navigate = useNavigate();
  const search = useSearch({
    strict: false,
  });
  const formParam = 'form' in search ? search.form : undefined;
  const modalParam = 'modal' in search ? search.modal : undefined;
  const formOrModal = formParam || modalParam;

  useEffectAfterRender(() => {
    navigate({
      search: (prev) => ({
        ...prev,
        action:
          // eslint-disable-next-line no-nested-ternary
          atom?.type && atom.action
            ? atom.action
            : !formOrModal
              ? undefined
              : // @ts-ignore
                prev.action,
        form:
          atom?.type && type === 'form'
            ? decamelize(atom.type, '-')
            : // @ts-ignore
              prev.form,
        modal:
          atom?.type && type === 'modal'
            ? decamelize(atom.type, '-')
            : // @ts-ignore
              prev.modal,
      }),
    });
  }, [atom?.type]);
};

export function useSearchParamsOnce(
  key: keyof SearchParams,
  options?: Options,
): SearchParamValue;
export function useSearchParamsOnce(
  key: (keyof SearchParams)[],
  options?: Options,
): SearchParamValue[];
export function useSearchParamsOnce(
  key: keyof SearchParams | (keyof SearchParams)[],
  options?: Options,
) {
  const navigate = useNavigate();
  const search = useSearch({
    strict: false,
  });

  const enabled = options?.enabled ?? true;

  const keys = useMemo(() => {
    if (!enabled) {
      return [];
    }

    return Array.isArray(key) ? key : [key];
  }, [enabled, key]);

  const values = useMemo(
    // @ts-ignore
    () => keys.map((key) => search[key]),
    [keys, search],
  );

  useEffect(() => {
    if (!keys.length) {
      return;
    }

    navigate({
      replace: true,
      search: (prev) => ({
        ...prev,
        ...keys.reduce((acc, curr) => {
          // @ts-ignore
          acc[curr] = undefined;
          return acc;
        }, {}),
      }),
    });
  }, [keys, navigate]);

  return (values.length <= 1 ? values[0] : values) as
    | SearchParamValue
    | SearchParamValue[];
}

export const zFiltersArray = (
  schema?: z.ZodTypeAny,
  defaultValue?: unknown[],
) =>
  z
    .union([
      z.array(schema ?? z.string()),
      z.undefined().transform(() => []),
      z
        .string()
        .transform((val) => (val ? [val] : []))
        .pipe(z.array(schema ?? z.string())),
    ])
    // TODO: default should come after optional for correct types,
    // but as of Nov 21st 2024 it was causing 91 errors in 52 files.
    .default(defaultValue ?? [])
    .optional()
    .catch([]);
